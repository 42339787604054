.full-page-loader {
  --animation-speed: 8s;
  --animation-loop: infinite;
}

@keyframes loader {
  0%{
    background: theme('colors.biz-off-white');
    width: 0;
  }10%{
     width: 100%;
   }39%{
      background: theme('colors.biz-off-white');
    }40%{
       background: theme('colors.biz-off-white');
       width: 0;
     }60%{
        width: 100%;
      }80%{
         width: 0;
       }100%{
          background: theme('colors.biz-off-white');
          width: 0;
        }
}

@keyframes loaderAlt {
  0%{
    background: theme('colors.primary-500');
    width:100%;
  }09%{
     background: theme('colors.primary-500');
   }10%{
      background: theme('colors.primary-400');
      width: 0;
    }40%{
       width: 100%;
     }59%{
        background: theme('colors.primary-400');
      }60%{
         background: theme('colors.primary-500');
         width: 0;
       }80%{
          width: 100%;
        }100%{
           background: theme('colors.primary-500');
           width: 100%
         }
}

.loader__bar:before{
  animation: loader ease var(--animation-speed) var(--animation-loop);
  animation-delay: 200ms;
  background: theme('colors.primary-500');
  background-size: 200% 200%;
  content: "";
  height: 100%;
  width: 100%;
}

.loader__bar:after{
  animation: loaderAlt ease var(--animation-speed) var(--animation-loop);
  animation-delay: 200ms;
  background: theme('colors.primary-500');
  background-size: 200% 200%;
  content: "";
  height: 100%;
  width: 100%;
}

.loader__bar--delay-1:before,
.loader__bar--delay-1:after{
  animation-delay: 300ms;
}

.loader__bar--delay-2:before,
.loader__bar--delay-2:after{
  animation-delay: 400ms;
}

.loader__bar--delay-3:before,
.loader__bar--delay-3:after{
  animation-delay: 500ms;
}

.loader__bar--delay-4:before,
.loader__bar--delay-4:after{
  animation-delay: 600ms;
}

.loader__bar--delay-5:before,
.loader__bar--delay-5:after{
  animation-delay: 700ms;
}
