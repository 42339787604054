@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Rubik';
    src: url('./assets/fonts/Rubik/static/Rubik-Regular.ttf') format('truetype');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Rubik';
    src: url('./assets/fonts/Rubik/static/Rubik-Medium.ttf') format('truetype');
    font-weight: 500;
  }
  @font-face {
    font-family: 'Rubik';
    src: url('./assets/fonts/Rubik/static/Rubik-SemiBold.ttf') format('truetype');
    font-weight: 600;
  }
  @font-face {
    font-family: 'Rubik';
    src: url('./assets/fonts/Rubik/static/Rubik-Bold.ttf') format('truetype');
    font-weight: 700;
  }
  @font-face {
    font-family: 'Rubik';
    src: url('./assets/fonts/Rubik/static/Rubik-ExtraBold.ttf') format('truetype');
    font-weight: 800;
  }
  :root {
    --color-primary-100: #fff6ed;
    --color-primary-200: #ffd6ad;
    --color-primary-300: #ffc182;
    --color-primary-400: #ffa347;
    --color-primary-500: #ff8000;
    --color-primary-600: #d36a00;
    --color-primary-700: #9e4f00;
    --color-primary-800: #5b2e00;
    --color-primary-900: #241200;
    --toastify-color-error: #d32f2f;
  }
}

@layer components {
  .heading-100 {
    font-size: theme('fontSize.x-large');
    font-weight: theme('fontWeight.weight-700');
  }
}

* {
  scrollbar-color: var(--color-primary-200) var(--color-primary-400);
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-200);
}

/* TODO: Rather dirty hack, needs to be changed to another way of reseting autofill styles when webkit introduces one */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 999999s 0s, color 999999s 0s;
  -webkit-transition: background-color 999999s 0s, color 999999s 0s;
  -moz-transition: background-color 999999s 0s, color 999999s 0s;
  -ms-transition: background-color 999999s 0s, color 999999s 0s;
  -o-transition: background-color 999999s 0s, color 999999s 0s;
  -webkit-filter: grayscale(100%) brightness(110%);
}
