@media screen and (prefers-color-scheme: dark) {
  .react-calendar {
    background: #282c34;
    border: 1px solid #555;
    color: white;
  }

  .react-calendar button:enabled:hover,
  .react-calendar button:enabled:focus {
    background-color: #555;
  }
}
